import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo/Seo'

const privacyPolicy = () => {
  return (
    <>
      <Layout title='Affiliate Disclosure' subtitle='affiliate disclosure'>
        <SEO
          title='TOYREADS - Affiliate Disclosure'
          pathname='affiliate-disclosure'
          desc='the rules and regulations we follow by for affiliate products'
        />
        <h1 className='title'>Disclaimer for TOYREADS</h1>

        <p>
          If you require any more information or have any questions about our
          site's disclaimer, please feel free to contact us by email at
          support@toyreads.com
        </p>

        <h2>Disclaimers for TOYREADS</h2>

        <h2>Consent</h2>

        <p>
          By using our website, you hereby consent to our disclaimer and agree
          to its terms.
        </p>

        <h2>Update</h2>

        <p>
          Should we update, amend or make any changes to this document, those
          changes will be prominently posted here.
        </p>
      </Layout>
    </>
  )
}

export default privacyPolicy
